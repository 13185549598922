import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { Grid, Header, Container, Divider } from 'semantic-ui-react'
import Layout from '../components/Layouts/default'

class MenuPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container text style={{ marginTop: 20 }}>
          <Header as="h1" textAlign="center">
            Vệ sinh an toàn thực phẩm
          </Header>
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={7} stretched>
                <ImageZoom>
                  <StaticImage
                    src="https://s3.kstorage.vn/savor-bread/src/assets/images/giay-phep-vsattp/cs-23-71-nguyen-thi-dinh.jpg"
                    alt="Giấy phép vệ sinh an toàn thực phâm"
                  />
                </ImageZoom>
              </Grid.Column>
              <Grid.Column width={7} stretched>
                <Grid.Row>
                  <ImageZoom>
                    <StaticImage
                      src="https://s3.kstorage.vn/savor-bread/src/assets/images/giay-phep-vsattp/foto1.jpg"
                      alt="Đưa hàng cho shipper"
                    />
                  </ImageZoom>
                </Grid.Row>
                <Divider hidden />
                <Grid.Row>
                  <ImageZoom>
                    <StaticImage
                      src="https://s3.kstorage.vn/savor-bread/src/assets/images/giay-phep-vsattp/foto2.jpg"
                      alt="Hình ảnh bếp"
                    />
                  </ImageZoom>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Layout>
    )
  }
}
export default MenuPage
